<template>
  <div class="row">
    <div class="cell">{{ product.sku }}</div>
    <div class="cell">{{ product.title }}</div>
    <div class="cell">{{ product.brand }}</div>
    <ProductBranchAvailability
      v-for="x in availabilities"
      :key="x.branch.id"
      :availability="x"
      :product-id="product.id"
    />
  </div>
</template>

<script>
import ProductBranchAvailability from "@/views/Products/shared/components/ProductBranchAvailability";

export default {
  name: "ProductItem",
  components: {
    ProductBranchAvailability,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    branches: {
      type: Object,
      required: true,
    },
  },
  computed: {
    availabilities() {
      const res = [];
      for (let x in this.branches) {
        const branchId = parseInt(x);
        const availability = this.product.availability.find(
          (av) => av.branch_id === branchId && av.product_id === this.product.id
        );
        let data = {
          isAvailable: false,
          branch: this.branches[x],
          price: 0,
          pre_order: 0,
          loan_period: 0,
          tax: 0,
        };
        if (availability) {
          const { price, pre_order, loan_period, tax } = availability;
          data = { ...data, price, pre_order, loan_period, tax };
        }
        res.push(data);
      }
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  //border: 1px solid #606266;
  .cell {
    width: 100px;
    flex-shrink: 0;
    border: 1px solid;
    overflow: hidden;
  }
}
</style>
