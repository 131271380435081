<template>
  <div style="margin: -30px">
    <div class="row">
      <div v-for="x in tableCaptions" :key="x" class="cell">{{ x }}</div>
    </div>
    <RecycleScroller
      class="scroller"
      :items="products"
      :item-size="100"
      key-field="id"
      v-slot="{ item }"
    >
      <ProductItem style="height: 100px" :product="item" :branches="branches" />
    </RecycleScroller>
  </div>
</template>

<script>
import { getAllProducts } from "@/api/product";
import ProductItem from "@/views/Products/shared/components/ProductItem";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  name: "list-all",
  components: {
    ProductItem,
    RecycleScroller,
  },
  data() {
    return {
      products: [],
      branches: null,
    };
  },
  computed: {
    tableCaptions() {
      const res = ["SKU", "model", "brand"];
      if (this.branches) {
        for (const resKey in this.branches) {
          res.push("Доступно в " + this.branches[resKey]["branch_code"]);
          res.push("Цена в " + this.branches[resKey]["branch_code"]);
          res.push("Предзаказ в " + this.branches[resKey]["branch_code"]);
        }
      }
      return res;
    },
  },
  async mounted() {
    const { products, branches } = await getAllProducts();
    this.products = products;
    this.branches = branches;
  },
};
</script>

<style lang="scss" scoped>
.scroller {
  height: calc(100vh - 60px);
  font-size: 12px;
}

.row {
  display: flex;
  //border: 1px solid #606266;
  .cell {
    width: 100px;
    flex-shrink: 0;
    border: 1px solid;
    overflow: hidden;
  }
}
</style>
