<template>
  <div class="row">
    <div class="cell">{{ availability.isAvailable }}</div>
    <div class="cell">{{ availability.price }}</div>
    <div class="cell">{{ availability.pre_order }}</div>
  </div>
</template>

<script>
export default {
  name: "ProductBranchAvailability",
  props: {
    productId: {
      type: Number,
      required: true,
    },
    availability: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  //border: 1px solid #606266;
  .cell {
    width: 100px;
    flex-shrink: 0;
    border: 1px solid;
  }
}
</style>
